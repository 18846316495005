.profile{
    display: flex;
}
.profileRight{
    flex: 9;
}
.profieRightBottom{
    display: flex;
}
.profileCover{
    height: 320px;
    position: relative;
}
.profileCoverImg{
    width: 100%;
    height: 250px;
    object-fit: cover;
}
.profileUserImg{
    width: 150px;
    height: 150px;
    border-radius: 50%;
    position: absolute;
    object-fit: cover;
    left: 0;
    right: 0;
    margin: auto;
    top: 150px;
    border: 3px solid white;
}
.profileInfo{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.profileInfoName{
    font-size: 24px;

}
.profileInfoDesc{
    font-weight: 300;
}
.profieRightBottom{
    display: flex;
}


/* This is for The Mobile View */
@media (max-width: 600px) {
    .side{
       display: none;
    }
    .rightbarFollowing{
        padding-right: 11%;
        padding-left: 12%;
        
    }
  .rightbarTitle{
   color: #aa04aa;
   padding: 5%;
  }
  .rightbarInfoKey{
    padding: 5%;
  }
/* .container{ */
    /* display: flex;
    justify-content: space-between;

    
    
}
.bb{
    padding-right: 20px;
    
} */


.headerContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 20px;
  }
  
  .buttonContainer {
    margin-left: 10px; 
  }
}


/* This is For Desktop View */
@media (min-width: 1024px) {
  .userFriend{
    display: none;
  }
  }

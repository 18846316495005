.share{
    width: 100%;
   
    border-radius: 10px;
    box-shadow: 4px 2px 22px -3px rgba(0,0,0,0.75);
-webkit-box-shadow: 0px 0px 18px -8px rgba(0,0,0,0.65);
-moz-box-shadow: 0px 0px 18px -8px rgba(0,0,0,0.65);
}
.shareWrapper{
    padding: 10px;
}
.shareTop{
    display: flex;
    align-items: center;

}
.shareProfileImg{
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 10px;
}
.shareInput{
    border: none;
    width: 80%;
}
.shareInput:focus{
    outline: none;
}
.shareHr{
    margin: 20px;
}
.shareButton{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.shareOptions{
    display: flex;
    margin-left: 20px;

}
.shareOption{
    display: flex;
    align-items: center;
    margin-right: 15px;
    cursor: pointer;
}
.shareIcon{
    font-size: 18px;
    margin-right:3px ;
}

.shareOptionText{
    font-size: 14px;
    font-weight: 500;
}
.shareButto{
    border: none;
    padding: 7px;
    border-radius: 5px;
    background-color: purple;
    font-weight: 500;
    color: white;
    margin-right: 20px;
    cursor: pointer;
}
.shareImgCoontainer{
  padding: 0 20px 10px 20px;
  position: relative;
}
.shareImg{
    width: 100%;
    object-fit: cover;
}
.shareCancelImg{
    position: absolute;
    top: 0;
    right: 20px;
    cursor: pointer;
    opacity: 0.7;

}
@media (max-width: 600px) {
    .share {
        height: auto;
        border-radius: 0;
        box-shadow: 4px 2px 22px -3px rgba(0,0,0,0.75);
        -webkit-box-shadow: 0px 0px 18px -8px rgba(0,0,0,0.65);
        -moz-box-shadow: 0px 0px 18px -8px rgba(0,0,0,0.65);
    }
    .shareWrapper {
        padding: 5px;
    }
    .shareTop {
        flex-direction: column;
        align-items: flex-start;
    }
    .shareProfileImg {
        width: 30px;
        height: 30px;
        margin-right: 5px;
    }
    .shareInput {
        width: 100%;
    }
    .shareHr {
        margin: 10px;
    }
    .shareOptions {
        margin: 10px 0;
        justify-content: center;
    }
    .shareOption {
        margin-right: 10px;
        font-size: 12px;
    }
    .shareIcon {
        font-size: 16px;
        margin-right: 2px;
    }
    .shareOptionText {
        font-size: 12px;
    }
    .shareButton {
        flex-direction: column;
        align-items: center;
    }
    .shareButto {
        padding: 5px;
        font-size: 14px;
        margin-right: 0;
    }
}

.topbarContainer{
    height: 50px;
    width: 100%;
    background-color: #aa04aa;
    display: flex;
    align-items: center;
    position: sticky;
    top: 0;
    z-index: 999;
}
.topbarLeft{
  flex: 3;
}
.logo{
    font-size: 24px;
    margin-left: 20px;
    font-weight: bold;
    color: white;
    cursor: pointer;
}
.topbarCenter{
    flex: 5;
   
}
.searchbar{
    width: 100%;
    height: 20px;
    background-color: white;
    border-radius: 30px;
    display: flex;
    align-items: center;
}
.searchIcon{
    font-size: 20px !important;
    margin-left:10px ;
}
.searchInput{
    border: none;
    width: 70%;
}
.searchInput:focus{
    outline: none;
}

.topbarRight{
    flex:4;
    display: flex;
    align-items: center;
    justify-content: space-around;
    color: white;
   
}
.topbarLink{
    margin-right: 10px;
    font-size: 14px;
    cursor: pointer;
}
.topbarIcons{
 display: flex;
}
.topbarIconItem{
    margin-right: 15px;
    cursor: pointer;
    position: relative;
}
.topbarIconBadge{
    width: 15px;
    height: 15px;
    background-color: red;
    border-radius: 50%;
    color: white;
    position: absolute;
    top:-5px;
    right: -5px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
}
.topbarImg{
    max-width:32px;
    height: 32px;
    border-radius: 50%;
    object-fit: cover;
    cursor: pointer;
}


/* Media query for smaller screens */
@media (max-width: 600px) {
.logo{
display: block;
font-size: 17px;
position: static;
}
.person{
    display: none;
}
.topbarLeft{
    padding-right: 10px;
}

.searchbar.open{
 display: none;
 
}
.searchbar:not(open) {
    display: flex;
  }
.topbarLink{
    display: none;
}
 .topbarImg{
    padding-right: 2px;
}
.topbarContainer{
    width: 100%;
 
}
.topbarIconItem{
    padding-right: 6px;
}
.searchbar{
    padding-left: 2px;
    margin-right: 20%;
    
}
.notify{
    display: none;
}

}

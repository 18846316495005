.Rightbar{
    flex: 3.5;
}
.rightbarWrapper{
    padding: 20px 20px 0 0;
}
.birthdayContainer{
    display: flex;
    align-items: center;
}
.birthdayImg{
    width: 40px;
    height: 40px;
    margin-right: 10px;
}
.birthdayText{
    font-weight: 300;
    font-size: 15px;
}
.rightbarAd{
    width: 100%;
    border-radius: 10px;
    margin: 30px 0;
}
.rightbarfriendList{
    padding: 0;
    margin: 0;
    list-style: none;

}
.rightBarFriend{
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}
.rightbarProfileImgContainer{
    margin: 10px;
    position: relative;
}
.rightbarProfileImg{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
}
.rightbarOnline{
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: green;
    position: absolute;
    top: -2px;
    right: 0;
    border: 2px solid white;
}
.rightBarUsername{
    font-weight: 500;

}
.rightbarTitle{
    margin-bottom: 20px;
}
.rightbarTitle{
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 10px;
}
.rightbarFollowingImg{
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 5px;
}
.rightbarInfo{
    margin-bottom: 30px;
}
.rightbarInfoItem{
    margin-bottom: 10px;
}
.rightbarInfoKey{
    font-weight: 500;
    margin-right: 15px;
    color: #555;
}
.rightbarInfoValue{
    font-weight: 300;
}
.rightbarfollowings{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.rightbarFollowing{
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    cursor: pointer;
}
.rightbarFollowButton{
    margin-top: 30px;
    margin-bottom: 10px;
    border: none;
    background-color:  purple;
    color: white;
    border-radius: 5px;
    padding: 5px 10px ;
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
}
/* .sideBar{
    flex: 3;
    height: calc(100vh - 50px);
    overflow-y: scroll;
    position: sticky;
    top: 50px;
}

::-webkit-scrollbar{
    width: 5px;
}
::-webkit-scrollbar-track {
    background-color: aliceblue
}
::-webkit-scrollbar-thumb{
   background-color: rgb(167, 167, 167)
}
.sidebarWrapper{
    padding: 20px;
}

.sidebarList{
    padding: 0;
    margin: 0;
    list-style: none;
}
.sidebarListItem{
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.sidebarIcon{
    margin-right: 15px;
}
.sidebarButton{
    width: 150px;
    border: none;
    padding: 10px;
    border-radius: 5px;
    font-weight: 500;
}
.sidebarHr{
    margin: 20px 0;
}
.sidebarFriendList{
    padding: 0;
    margin: 0;
    list-style: none;
} */


/* Default styles */
.sideBar{
    flex: 3;
    height: calc(100vh - 50px);
    overflow-y: scroll;
    position: sticky;
    top: 50px;
}

::-webkit-scrollbar{
    width: 5px;
}
::-webkit-scrollbar-track {
    background-color: aliceblue
}
::-webkit-scrollbar-thumb{
   background-color: rgb(167, 167, 167)
}
.sidebarWrapper{
    padding: 20px;
}

.sidebarList{
    padding: 0;
    margin: 0;
    list-style: none;
}
.sidebarListItem{
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.sidebarIcon{
    margin-right: 15px;
}
.sidebarButton{
    width: 150px;
    border: none;
    padding: 10px;
    border-radius: 5px;
    font-weight: 500;
}
.sidebarHr{
    margin: 20px 0;
}
.sidebarFriendList{
    padding: 0;
    margin: 0;
    list-style: none;
}

/* Media query for smaller screens */
@media (max-width: 600px) {
  .sideBar {
    flex: 1;
    height: calc(100vh - 50px);
    height: 100vh;
    overflow-y: initial;
    top: 50px;
    background-color: #aa04aa;
    position: sticky;
    border: 2px solid #ece7e7;
    
  }
  
  .sidebarWrapper {
    padding: 10px;

  }
  
  
  .sidebarListItem {
    margin-bottom: 45px;
    cursor: pointer;
    justify-content: space-between;
    

  }


.sidebarIcon{
    margin-right: 8px;
    font-size: 18px;
    color: white;
    height: 80px;
   

}
.sidebarListItemText{
    display: none;
}
.sidebarFriendList{
    display: none;
}
.sidebarButton{
    display: none;
}

}

.post{
    width: 100%;
    border-radius: 10px;
    box-shadow: 4px 2px 22px -3px rgba(0,0,0,0.75);
   -webkit-box-shadow: 0px 0px 18px -8px rgba(0,0,0,0.65);
    -moz-box-shadow: 0px 0px 18px -8px rgba(0,0,0,0.65);
    margin: 30px 0;
}

.postWrapper{
    padding: 10px;

}
.postTop{
    display: flex;
    align-items: center;
    justify-content: space-between; 
}
.postTopLeft{
    display: flex;
    align-items: center;

}
.postProfileImg{
    width: 32px;
    height: 32px;
    border-radius: 50%;
    object-fit: cover;
}
.postUsername{
    font-size: 15px;
    font-weight: 500;
    margin: 0 10px;
}
.postDate{
    font-size: 12px;
}
.postCenter{
    margin: 20px 0;
}
.postImg{
    margin-top: 20px;
    width: 100%;
    max-height: 500px;
    object-fit: contain;

}
.postBottom{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.postBottomLeft{
    display: flex;
    align-items: center;
}
.likeIcon{
    cursor: pointer;
    height: 24px;
    margin-right: 5px;
    width: 24px;    
}
.postLikeCounter{
    font-size: 15px;
}
.postCommentText{
    cursor: pointer;
    border-bottom: 1px dashed grey;
    font-size: 15px;
}


/* Media query for smaller screens */
@media (max-width: 600px) {
    .post {
        border-radius: 0;
        box-shadow: 4px 2px 22px -3px rgba(0,0,0,0.75);
        -webkit-box-shadow: 0px 0px 18px -8px rgba(0,0,0,0.65);
        -moz-box-shadow: 0px 0px 18px -8px rgba(0,0,0,0.65);
        margin: 15px 0;
    }
    .postWrapper {
        padding: 5px;
    }
    .postUsername {
        font-size: 13px;
        margin: 0 5px;
    }
    .postDate {
        font-size: 10px;
    }
    .postImg {
        margin-top: 10px;
        max-height: 300px;
    }
    .postLikeCounter {
        font-size: 13px;
    }
    .postCommentText {
        font-size: 13px;
    }
}

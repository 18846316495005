.homeContainer{
    display: flex;
    width: 100%;
}
@media (max-width: 600px) {
    .Rightbar{
        display: none;
    }
    .Side{
        display: none;
    }

}